<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<h5 v-if="!toEdit" class="subtitle-1 font-weight-regular">Создание поста</h5>
				<h5 v-else class="subtitle-1 font-weight-regular">Редактирование поста</h5>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text>
				<v-container>
					<v-form ref="form">
						<v-row>
							<!-- Тема вопроса РЕДАКТИРОВАНИЯ -->
							<v-col cols="12" v-if="toEdit">
								<div class="subtitle-1 black--text">
									<span>{{ data.name }} </span>
          				<span>{{ data.surname }}</span>
								</div>
								<div v-if="data.apartment" class="subtitle-1 black--text">
									<span>{{ data.apartment }} </span>
								</div>
								<div v-if="data.apartment_number">
									<span class="subtitle-2">Номер квартиры: {{ data.apartment_number }} </span>
								</div>
							</v-col>
							<!-- Тема вопроса СОЗДАНИЕ -->
							<v-col cols="12" v-else>
								<span class="subtitle-2 font-weight-light">
									Адрес дома
								</span>
								<v-autocomplete
									v-model="data.house_id"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:items="houses"
									item-text="apartment_address"
									item-value="_id"
									:rules="[v => !!v]"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12">
								<span class="subtitle-2 font-weight-light">
									Описание поста
								</span>
								<v-textarea
									v-model="data.description"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:rules="[v => !!v]"
								>
								</v-textarea>
							</v-col>
							<v-col cols="12">
								<v-file-input
									multiple
									label="Прикрепить фото"
									prepend-icon="mdi-camera"
									v-model='data.images' 
									accept="image/*" 
								></v-file-input>
							</v-col>
							<!-- Адрес -->
							<!-- <v-col cols="12" class="pt-0">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<v-autocomplete
									v-if="data.apartment.house"
									v-model="data.apartment.house._id"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:items="houses"
									item-text="apartment_address"
									item-value="_id"
									:rules="[v => !!v]"
								>
								</v-autocomplete>
								<v-autocomplete
									v-else
									v-model="data.apartment_address"
									class="border-all"
									solo
									hide-details
									flat
									dense
									:items="houses"
									item-text="apartment_address"
									item-value="_id"
									:rules="[v => !!v]"
								>
								</v-autocomplete>
							</v-col> -->
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="loading"
					@click="addPost"
				>
					{{ toEdit ? "Сохранить" : "Добавить" }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "FaqModal",

	props: {
		toEdit: {
			type: Object,
			required: false,
			default: undefined,
		},
	},

	data() {
		return {
			files: null,
			alert: null,
			dialog: true,
			loading: false,
			data: {
				images: null,
				_id: null,
				description: "",
				apartment_number: "",
				house_id: "",
				apartment: "",
				name: "",
				surname: ""
			},
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			role: "auth/GET_USER_ROLE_VUEX",
		}),
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
	},

	methods: {
		// Валидация
		customValidation() {
			console.log('validate')
			return this.$refs.form.validate();
		},
		// Добавление заявки
		async addPost() {
			this.alert = null;

			if (!this.customValidation()) return;

			try {
				this.loading = true;

				let res;
			
				if (!this.toEdit) {
					const data = this.data.toFormData();
					res = await this.$api.administrator.add_post(data);
				} else {
					console.log('edit!!!!', this.data);
					const data = this.data.toFormData();
					res = await this.$api.administrator.edit_post({
						id: this.data._id,
						data: data,
					});
				}

				this.alert = {
					color: "success",
					message: res.success,
				};

				if (this.alert) {
					setTimeout(() => this.closeModal(), 700);
				}

				this.data = {
					name: "",
					apartment_address: "",
					house_id: "",
				};

				this.$refs.form.reset();

				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.loading = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	created() {
		if (this.toEdit) {
			// this.data = { ...this.toEdit };
			this.data.house_id = this.toEdit.apartment.house._id
			this.data.apartment = this.toEdit.apartment.house.apartment_address
			this.data.apartment_number = this.toEdit.apartment.apartment_number
			this.data.name = this.toEdit.user.name
			this.data.surname = this.toEdit.user.surname
			this.data.description = this.toEdit.description
			this.data._id = this.toEdit._id
		}
	},
};
</script>
