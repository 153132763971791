var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq"},[_c('v-data-table',{staticClass:"overflow-x elevation-0",attrs:{"height":"100%","item-key":"name","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.options.itemsLength},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"justify-end pa-3 border-bottom",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"","items-per-page-options":[],"disable-items-per-page":"","disable-pagination":_vm.loading,"show-first-last-page":true},on:{"update:options":updateOptions},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"d-flex flex-sm-grow-1 align-center justify-start"},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){_vm.showModalCreate = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v("Создать пост")])],1),_c('v-text-field',{staticClass:"border-all mr-5",attrs:{"placeholder":"Поиск по описанию","prepend-inner-icon":"mdi-magnify","hide-details":"","dense":"","solo":"","flat":"","clearable":""},on:{"change":function($event){return _vm.search()}},model:{value:(_vm.filter.description),callback:function ($$v) {_vm.$set(_vm.filter, "description", $$v)},expression:"filter.description"}})],1)]},proxy:true}],null,true)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('td',[_c('span',[_vm._v(_vm._s(item.user.name)+" ")]),_c('span',[_vm._v(_vm._s(item.user.surname))])]):_vm._e()]}},{key:"item.apartment_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.apartment.apartment_number)+" ")])]}},{key:"item.apartment_address",fn:function(ref){
var item = ref.item;
return [(item.apartment.house)?_c('td',[_vm._v(" "+_vm._s(item.apartment.house.apartment_address)+" ")]):_vm._e()]}},{key:"item.delete_question",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{staticClass:"subtitle-2 text-capitalize",attrs:{"color":"red white--text","small":"","depressed":"","loading":_vm.isDeleting === item._id},on:{"click":function($event){$event.stopPropagation();return _vm.deletePost(item)}}},[_vm._v(" Удалить ")])],1)]}}],null,true)}),(_vm.showModalCreate)?_c('PostsModal',{attrs:{"to-edit":_vm.toEdit},on:{"close-modal":_vm.closeModal,"update-info":_vm.loadData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }