<template>
	<div class="faq">
		<v-data-table
			class="overflow-x elevation-0"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
        <template v-slot:prepend>
          <div class="d-flex flex-sm-grow-1 align-center justify-start">
            <!-- Кнопка создания -->
            <v-btn
              class="mr-5"
              color="primary"
              @click="showModalCreate = true"
              small
              depressed
            >
              <v-icon class="mr-1" small>mdi-plus</v-icon>
              <span class="body-2 font-weight-light">Создать пост</span>
            </v-btn>
            <v-text-field
                v-model="filter.description"
                class="border-all mr-5"
                placeholder="Поиск по описанию"
                prepend-inner-icon="mdi-magnify"
                hide-details
                dense
                solo
                flat
                clearable
								@change="search()"
              >
              </v-text-field>
          </div>
        </template>
				</v-data-footer>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.name`]="{ item }">
				<td v-if="item.user">
          <span>{{ item.user.name }} </span>
          <span>{{ item.user.surname }}</span>
				</td>
			</template>
			<template v-slot:[`item.apartment_number`]="{ item }">
				<td>
					{{ item.apartment.apartment_number }}
				</td>
			</template>
			<template v-slot:[`item.apartment_address`]="{ item }">
				<td v-if="item.apartment.house">
					{{ item.apartment.house.apartment_address }}
				</td>
			</template>
			<template v-slot:[`item.delete_question`]="{ item }">
				<td>
					<v-btn
						color="red white--text"
						class="subtitle-2 text-capitalize"
						small
						depressed
						:loading="isDeleting === item._id"
						@click.stop="deletePost(item)"
					>
						Удалить
					</v-btn>
				</td>
			</template>
		</v-data-table>
		<PostsModal
			v-if="showModalCreate"
			@close-modal="closeModal"
			:to-edit="toEdit"
			@update-info="loadData"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PostsModal from "@/components/PostsModal";

export default {
	name: "Home",
  components: { PostsModal },
	data() {
		return {
			firstfetch: false,
			left: null,
			right: null,
      showModalCreate: false,
			loading: false,
			showCreateModal: false,
			filter: {
				apartment_number: null,
				apartment_address: null,
				house_id: null,
				name: null,
				description: null,
			},
			filter2: null,
			isDeleting: -1,
			items: [],
			toEdit: null,
			query: null,
			headers: [
				{
					text: "ФИО",
					value: "name",
					sortable: false,
				},
				{
					text: "Описание",
					value: "description",
					sortable: false,
				},
				{
					text: "Номер квартиры",
					value: "apartment_number",
					sortable: false,
				},
				{
					text: "Дом",
					value: "apartment_address",
					sortable: false,
				},
				{
					text: "Удаление поста",
					value: "delete_question",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 0,
				pageCount: 0,
				itemsLength: 0,
			},
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},

	watch: {
		filter: {
			handler() {
				// let timeout;
				// clearTimeout(timeout)
				// timeout = setTimeout(() => this.loadData(), 700);
				
				
			},
			deep: true,
		},
		options: {
			handler() {
				if(!this.firstfetch) {
					const query = { ...this.query, page: this.options.page };
					this.loadData(query);
				}
				this.firstfetch = false
			},
			deep: true,
		},
	},

	methods: {
		search() {
			this.firstfetch = true;
			this.loadData();
		},	
		handleRowClick(item) {
			if (this.isDeleting !== -1) return;
			this.showModalCreate = true;
			this.toEdit = item;
		},

		async loadData() {
			try {
				this.loading = true;
				const res = await this.$api.administrator.load_posts({
					...this.filter,
					page: this.options.page,
				});
				this.options.page = res.page;
				this.options.pageCount = res.pages;
				this.options.itemsLength = res.pages * 10;
				this.items = res.records;
        console.log('items posts', this.items)
				console.log(this.items);
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async deletePost(item) {
			try {
				this.isDeleting = item._id;
				const res = await this.$api.administrator.delete_post(item._id);
				this.$root.snackbar.show({ message: res.success, color: "success" });
				this.loadData();
			} catch (error) {
				this.$root.snackbar.show({ message: error.message, color: "error" });
			} finally {
				this.isDeleting = -1;
			}
		},

		async closeModal() {
			this.showModalCreate = false;
			this.toEdit = null;
      console.log('null to edit!')
		},
    updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.loadData(query);
		},
	},

	created() {
		this.$store.dispatch("ksk/load_houses");
	},

	mounted() {
		this.loadData();
		this.firstfetch = true
	},
};
</script>

<style lang="scss" scoped>
.faq {
	padding: 20px;
	background: #fff;
	min-height: 90vh;
}
.custom-input.v-input {
	border: 1px solid #d8dbe0;
	border-radius: 4px;
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}
::v-deep .v-input > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
	padding: 0;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
